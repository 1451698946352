import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  useReactTable,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import CommonHeader from "../../components/view/common-header";
import CommonLayout from "../../components/view/common-layout";
import DotsDropdown from "../../components/DotsDropdown";
import AddUser from "../../components/view/AddUser";

const columnHelper = createColumnHelper();

const initialData = [
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Mahmadjakir Chavda",
    email: "mahamdjakir.c@indianic.com",
    role: "Admin",
    dateCreated: "1 Day ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Yash Suryavanshi",
    email: "yash.s@indianic.com",
    role: "Admin",
    dateCreated: "5 Days ago",
    status: "Inactive",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  {
    name: "Milind Sharma",
    email: "milind.s@indianic.com",
    role: "User",
    dateCreated: "2 Weeks ago",
    status: "Active",
    imageUrl: "../../images/sidebar-avatar.jpg",
  },
  // Add more entries as needed
];

const Users = () => {
  const [isAddUserPopupOpen, setAddUserPopupOpen] = useState(false);
  const toggleAddUserPopup = () => {
    // API call to submit the form data
    setAddUserPopupOpen((prev) => !prev);
  };
  // Store the data in a state to handle status toggling
  const [data, setData] = useState(initialData);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // Handle the status toggle between Active/Inactive
  const handleStatusToggle = (rowIndex) => {
    setData((prevData) =>
      prevData.map((row, index) =>
        index === rowIndex
          ? { ...row, status: row.status === "Active" ? "Inactive" : "Active" }
          : row
      )
    );
  };

  // Define the handleActionClick function here to avoid ESLint errors
  const handleActionClick = (row) => {
    // Implement any action for the three-dot button here
    console.log("Action clicked for:", row);
  };

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => (
        <div className="flex items-center">
          <img
            src={info.row.original.imageUrl}
            alt={info.getValue()}
            className="w-[28px] h-[28px] rounded-full mr-[8px]"
          />
          <span className="text-[14px] leading-[19.2px] text-[#30313D] font-['inter-medium']">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("email", {
      header: "Email",
      cell: (info) => <span className="text-[14px] leading-[19.2px] text-[#30313D] font-['inter-medium']">{info.getValue()}</span>
    }),
    columnHelper.accessor("role", {
      header: "Role",
      cell: (info) => (
        <span
          className={`block text-center rounded-full ${
            info.getValue() === "Admin"
              ? "w-[86px] h-[24px] leading-[22px] font-['inter-medium'] bg-[#F6FFF3] text-[#228403] border [border-style:solid] [border-color:#E9F3E6]"
              : "w-[86px] h-[24px] leading-[22px] font-['inter-medium'] bg-[#F7F7F7] text-[#020305] border [border-style:solid] [border-color:#EAEAEC]"
          }`}
        >
          {info.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor("dateCreated", {
      header: "Date Created",
      cell: (info) => <span className="text-[14px] leading-[19.2px] text-[#30313D] font-['inter-medium']">{info.getValue()}</span>
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => {
        const rowIndex = info.row.index;
        const isActive = data[rowIndex].status === "Active";
        return (          
           <div className="workflow-switch node-comm-switch flex items-center justify-start">
           <input
             type="checkbox"
             id="workflow-switch"
             className="h-[0] w-[0] invisible"
             checked={isActive}
             onChange={() => handleStatusToggle(rowIndex)}
           />
           <label
             className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
             htmlFor="workflow-switch"
           >
             Toggle
           </label>
           <div className="switch-spans flex items-center justify-center ml-[7px]">
             <span className="switch-span switch-span-1 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
               Inactive
             </span>
             <span className="switch-span switch-span-2 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
               Active
             </span>
           </div>
         </div>
        );
      },
    }),
    columnHelper.accessor("action", {
      header: "Action",
      cell: (info) => (
        <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handlePageSizeChange = (event) => {
    setPagination((old) => ({
      ...old,
      pageSize: Number(event.target.value),
      pageIndex: 0, // Reset to first page when page size changes
    }));
  };

  // Function to display the current range
  const getCurrentRange = () => {
    const start = pagination.pageIndex * pagination.pageSize + 1;
    const end = Math.min(start + pagination.pageSize - 1, data.length);
    return `Showing ${start} to ${end} of ${data.length} entries`;
  };

  // Function to create page numbers
  const renderPageNumbers = () => {
    const totalPages = table.getPageCount();
    const currentPage = pagination.pageIndex + 1; // Adjusting for 0-indexed pageIndex
    const maxPageButtons = 3; // Show first 3 pages and then dots

    const pageNumbers = [];

    // Always show the first page
    pageNumbers.push(
      <li key={1}>
        <button
          onClick={() => table.setPageIndex(0)}
          className={`pagination-button ${
            currentPage === 1 ? "bg-blue-100 text-blue-600" : "text-black"
          }`}
        >
          1
        </button>
      </li>
    );

    // Add pages 2 and 3
    for (let i = 2; i <= maxPageButtons; i++) {
      if (i < totalPages) {
        pageNumbers.push(
          <li key={i}>
            <button
              onClick={() => table.setPageIndex(i - 1)}
              className={`pagination-button ${
                currentPage === i ? "bg-blue-100 text-blue-600" : "text-black"
              }`}
            >
              {i}
            </button>
          </li>
        );
      }
    }

    // Add ellipsis if there are pages between the current view and the last page
    if (totalPages > maxPageButtons + 1) {
      pageNumbers.push(
        <li key="ellipsis">
          <span className="pagination-ellipsis">...</span>
        </li>
      );
    }

    // Always show the last page if there are more pages
    if (totalPages > 1) {
      pageNumbers.push(
        <li key={totalPages}>
          <button
            onClick={() => table.setPageIndex(totalPages - 1)}
            className={`pagination-button ${
              currentPage === totalPages ? "bg-blue-100 text-blue-600" : "text-black"
            }`}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };
  const dotoptions = [
    {
      imageSrc: '../../images/drop_edit.svg',
      label: 'Edit',
      onClick: () => {console.log('Edit clicked')},
    },
    {
      imageSrc: '../../images/drop_reset_password.svg',
      label: 'Reset password',
      onClick: () => {console.log('Reset Password clicked')},
    },
    {
      imageSrc: '../../images/drop_make_admin.svg',
      label: 'Make admin',
      onClick: () => {console.log('Make Admin clicked')},
    },
    {
      imageSrc: '../../images/drop_delete.svg',
      label: 'Delete',
      onClick: () => {console.log('Delete clicked')},
    }
  ];
  const iconSrc = '../../images/common-module-dots.svg';
  return (
    
    <CommonLayout>
      {isAddUserPopupOpen && (
      <AddUser
        isOpen={isAddUserPopupOpen}
        onClose={() => setAddUserPopupOpen(false)}        
      />
    )}
    <div className="workflow-listing w-[calc(100%_-_80px)] h-screen ml-auto bg-[#F2F5F7]">
      <CommonHeader />
      <div className="common-module-list px-[40px]">
        <div className="common-module-list-head flex items-center justify-between py-[25px]">
          <div className="workflow-listing-head-title text-[#1C1E21] font-['inter-bold'] text-[20px] not-italic font-normal leading-[normal]">
            Users
          </div>
          <button           
            onClick={toggleAddUserPopup}
            className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 w-[206px] h-[48px] [box-shadow:none] bg-[#006DE0] text-[#FFF] font-[inter-semibold] text-[16px] not-italic font-normal leading-[normal] !py-0 rounded-[4px]"
          >
            <span className="mr-[6px]">Create New</span>
            <img src="../../images/add-node-plus.svg" alt="add-node-plus" />
          </button>
        </div>
        <div className="common-module-list-head-body bg-[#fff]">
        <div className="common-module-list-actions p-[18px] pb-0">
              <div className="common-module-list-actions-wrap border-b [border-bottom-style:solid] [border-bottom-color:#DCE4E8] flex items-start justify-between pb-[18px]">
                <div className="common-module-list-action-search flex align-center justify-start border [border-style:solid] [border-color:#DCE4E8] rounded-[6px] px-[14px] w-[267px]">
                  <img src="../../images/credentials-dropdown-search.svg" alt="credentials-dropdown-search" width={13} />
                  <input type="text" placeholder="Search for people" className="text-[#1C1E21] h-[32px] text-[12px] w-full pl-[10px] font-[inter-medium]" />
                </div>

                <div className="common-module-list-action-buttons flex items-start justify-end">
                  <div className="common-module-list-action-button common-module-list-action-button-filter cursor-pointer w-[92px] h-[32px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]">
                    <img src="../../images/filter-button-icon.svg" alt="filter-button-icon" width={19} />
                    <span className="font-[inter-semibold] text-[11px] text-[#1C1E21] ml-[4px]">Filter</span>
                  </div>
                  <div className="common-module-list-action-button common-module-list-action-button-sortby ml-[8px] cursor-pointer w-[92px] h-[32px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]">
                    <img src="../../images/sort-by-icon.svg" alt="sort-by-icon" width={19} />
                    <span className="font-[inter-semibold] text-[11px] text-[#1C1E21] ml-[4px]">Sort By</span>
                  </div>                  
                </div>
              </div>
            </div>
          <div className="common-module-list-rows p-[18px] pb-0 max-h-[calc(100vh_-_233px)] overflow-auto">
            <table style={{"width":"100%"}}>
              <thead className="text-left text-[14px] font-['inter-semibold'] bg-[#f0f1f3] leading-[19.6px]">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        className="px-[15px] py-[20px]"
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === "desc" ? " 🔽" : " 🔼"
                        ) : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className="border-b [border-bottom-style:solid] [border-bottom-color:#DCE4E8]">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="p-[15px]">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Page Size Selector */}
            <div className="flex items-center justify-between py-[16px]">
              <div>
                <label htmlFor="pageSize" className="mr-[15px] font-['inter-semibold'] text-[12px] text-[#595A64]">
                  {getCurrentRange()}
                </label>
                <select
                  id="pageSize"
                  value={pagination.pageSize}
                  onChange={handlePageSizeChange}
                  className="border rounded px-2 py-1 text-[14px] font-['inter-medium']"
                >
                  {[10, 20, 30, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              {/* Pagination Controls */}
              <div className="flex justify-center">
            <nav aria-label="Page navigation">
              <ul className="pagination flex items-center space-x-2">
                {/* Previous Page Button */}
                <li className="w-[32px] h-[32px] flex items-center justify-center rounded-[6px]">
                  <button
                    className={`flex items-center justify-center pagination-button h-full w-full rounded-[6px] border [border-style:solid] [border-color:#D1D4D9] ${
                      !table.getCanPreviousPage()
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-black"
                    }`}
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <img src="/images/paginate-left-icon.svg" alt="paginate-left-icon" />
                  </button>
                </li>

                {/* Page Numbers */}
                {Array.from({ length: table.getPageCount() }, (_, idx) => (
                  <li key={idx} className="w-[32px] h-[32px] flex items-center justify-center rounded-[6px]">
                    <button
                      onClick={() => table.setPageIndex(idx)}
                      className={`flex items-center justify-center pagination-button h-full w-full rounded-[6px] ${
                        pagination.pageIndex === idx
                          ? "bg-[#E8F0FC] text-[#30313D]"
                          : "text-black"
                      }`}
                    >
                      {idx + 1}
                    </button>
                  </li>
                ))}

                {/* Next Page Button */}
                <li className="w-[32px] h-[32px] flex items-center justify-center rounded-[6px]">
                  <button
                    className={`flex items-center justify-center pagination-button h-full w-full rounded-[6px] border [border-style:solid] [border-color:#D1D4D9] ${
                      !table.getCanNextPage()
                        ? "text-[#30313D] cursor-not-allowed"
                        : "text-black"
                    }`}
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    <img src="/images/paginate-right-icon.svg" alt="paginate-right-icon" />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </CommonLayout>
  );
};

export default Users;
