import React, { useState } from 'react';
import Select from 'react-select';
import CustomSelectIconOption from './CustomSelectIconOption';
import CustomSelectIconValue from './CustomSelectIconValue';

const PopupCred = ({ isOpen, onClose, items, onContinue }) => {
    const [selectedOption, setSelectedOption] = useState(null);
  
    const handleChange = (selected) => {
      setSelectedOption(selected);
    };
  
    const handleContinue = () => {
      if (onContinue) {
        onContinue(selectedOption);
      }
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="credentials-popup credentials-popup-1 z-[100] fixed top-[0] left-[0] w-full h-full bg-[rgba(0,_0,_0,_0.5)] flex justify-center items-center">
        <div className="w-[624px] bg-[#fff] rounded-[6px] relative">
        <div className="popup-header rounded-tl-[14px] rounded-tr-[14px] bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px]">
          <div className="text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[28px] -tracking-[0.34px]">Add new credential</div>
          <button
            className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
            onClick={onClose}
          >
            <img src="../../images/close-popup.svg" alt="popup-close" />
          </button>
          
          </div>
          <div className="px-[29px] pt-[32px] pb-[32px]">
            <div className="midform-field midform-field-select relative mb-[30px]">
            <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[5px]">Select app or service <span className="text-[#df1941]">*</span></label>
          
            <Select
              className="midform-select-main add_credentials_dropdown"
              classNamePrefix="midform-select"
              value={selectedOption}
              onChange={handleChange}
              options={items}
              placeholder="Search for app..."         
              isSearchable={false}     
              components={{
                Option: CustomSelectIconOption,
                SingleValue: CustomSelectIconValue,
              }}
            />
          
          </div>
          <button
            onClick={handleContinue}
            className={`w-[150px] py-2 px-4 rounded text-white ${
              selectedOption ?
              'mx-auto [transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[36px] [box-shadow:none] bg-[#006DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]'
              :
              'mx-auto [transition:0.3s_opacity_ease] opacity-50 flex items-center justify-center border-0 h-[36px] [box-shadow:none] bg-[#006DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px] cursor-not-allowed'
            }`}
            disabled={!selectedOption}
          >
            Save
          </button>
          </div>
        </div>
      </div>
    );
};

export default PopupCred;
