import { useCallback, useEffect, useState } from "react";
import { Button, Space, Tabs, message } from "antd";
import {
  copyNodeWithConnections,
  copyNodeWithConnectionsCopy,
  createEditor,
  hasInputs,
  IsEntryNode,
} from "../editor";
import Select from "react-select";
import { useRete } from "rete-react-plugin";
import Popup from "reactjs-popup";
import DotsDropdown from "../components/DotsDropdown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonLayout from "../components/view/common-layout";
import createAPI from "../api";
import { useParams } from "react-router-dom";
import { importGraph } from "../services/import-export";
export interface VisibilityState {
  isVisible: boolean;
}

export interface RootState {
  visibility: VisibilityState;
}
export default function Canvas() {
  const API = createAPI();
  const { id } = useParams(); // Get the :id parameter from the URL
  const [showpopUp, setShowpopUp] = useState(false);
  const [showEntryNodeError, setShowEntryNodeError] = useState(true);
  const [showNodes, setShowNodes] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [modules, setModules] = useState<string[]>([]);
  const [isPopupVisable, setIsPopupVisable] = useState(false);
  const [editWorkFlowName, setEditWorkFlowName] = useState(false);
  const [workFlowName, setWorkFlowName] = useState("WorkFlow");

  function toggleNodes() {
    setShowNodes((prev) => !prev);
  }

  const closeModal = () => {
    setShowpopUp(false);
  };

  // Ensure that createEditor is not calling any hooks internally
  const create = useCallback(
    (el: HTMLElement) => {
      return createEditor(el, messageApi.error);
    },
    [messageApi]
  );

  // Ensure useRete is called at the top level
  const [ref, editor] = useRete(create);

  function checkInputs(arrayOfObjects) {
    // Helper function to determine if an object is empty
    const isEmpty = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    // Iterate over each object in the array
    for (let obj of arrayOfObjects) {
      // Check if the 'inputs' property exists and is not empty
      if (obj.inputs && !isEmpty(obj.inputs)) {
        return true; // Found a non-empty 'inputs', return true
      }
    }

    // If no non-empty 'inputs' are found, show an error or return false
    console.error("No data with non-empty inputs found.");
    return false;
  }

  useEffect(() => {
    if (editor) {
      console.log('>>>>>>>>>>>>>>>>DD',id)
      console.log(editor);
      
      // Adding pipes to handle node creation and checking for entry nodes
      editor.editor.addPipe(async (context) => {
        console.log("context.type", context);
        return context;
      });

      editor.editor.addPipe(async (context) => {
        if (context.type === "nodecreate") {
          let hasEntryPoint = true;

          editor.editor.getNodes().forEach((val) => {
            if (val?.isEntryNode) {
              hasEntryPoint = false;
            }
          });

          if (!editor.editor.getNodes().length && IsEntryNode(context.data)) {
            toast("To Enable Workflow, please add an entry Node", {
              position: "top-right",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
            setShowEntryNodeError(false);
          } else if (IsEntryNode(context.data) && hasEntryPoint) {
            toast("To Enable Workflow, please add an entry Node", {
              position: "top-right",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
            setShowEntryNodeError(false);
          }

          return context;
        }

        return context;
      });
      if(id !=null){
        API.get(
          `rete/get-workflow/${id}`,{
          headers: {
            "Requires-Auth": true, // This tells the interceptor to add the auth token
          },
        }).then(async (data)=>{
          editor.importNodeByid(data.data.workFlows.rete)
          console.log(data.data.workFlows.rete.nodes); 
        })
      }
    }
  }, [editor]);

  const options2 = [
    { value: "t1", label: "option1", no: 1 },
    { value: "t2", label: "option2", no: 1 },
    { value: "t3", label: "option3", no: 1 },
  ];

  async function Addnodes(node, index) {
    node.position = [100, index * 100];
    await editor.editor.addNode(node);
  }
  const [isActive, setIsActive] = useState(false);
  const [isSideClosed, setIsSideClosed] = useState(true);

  const sideHandleHover = () => {
    // Remove the side_close class immediately when the mouse enters
    setIsSideClosed(false);

    // Set the active class after a delay if needed
    setTimeout(() => {
      setIsActive(true);
    }, 300);
  };

  const sideHandleMouseLeave = () => {
    // Remove the active class immediately
    setIsActive(false);

    // Add the side_close class after 5 seconds
    setTimeout(() => {
      setIsSideClosed(true);
    }, 300); // 5 seconds
  };

  const sideHandleCloseClick = () => {
    // Reset both isActive and isSideClosed when the close button is clicked
    setIsActive(false);
    setIsSideClosed(false);
  };


  const handelSaveWorkFlow = async () => {
    let exportData = await editor?.saveModule(workFlowName);
     
    let apiData =  API.post(
      "rete/save", exportData,{
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    }).then((data)=>{
      toast("WorkFlow save successfully", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }).catch(err=>{
      toast(err.message, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }); 
  };
  return (
    <CommonLayout>
      <div>
        {editor && (
          <div>
            <div className="execution-sidebar border-r [border-right-style:solid] [border-right-color:#BECBD8] bg-[#FFF] [box-shadow:-12px_15px_50px_0px_rgba(0,_0,_0,_0.13)] w-[356px] fixed bottom-[0] left-[0] h-[calc(100%_-_70px)] z-[1] py-[21px] pl-[37px] pr-[19px] hidden">
              <div className="execution-sidebar-head mb-[29px]">
                <div className="execution-sidebar-head-title text-[#60809F] font-[inter-medium] text-[18px] not-italic font-normal leading-[normal]">
                  Executions
                </div>
                <div className="execution-sidebar-head-actions flex items-end justify-between">
                  <div className="execution-sidebar-head-action pb-[7px]">
                    <div className="execution-sidebar-head-checkbox flex items-center justify-start comm-custom-checkbox-parent">
                      <div className="execution-sidebar-head-checkbox-inn mr-[10px] relative leading-[0] comm-custom-checkbox">
                        <input
                          type="checkbox"
                          className="comm-custom-checkbox-input w-[20px] h-[20px] absolute top-[0] left-[0] opacity-0 cursor-pointer z-[1]"
                        />
                        <span className="comm-custom-checkbox-span border border-solid border-[#A5ADBA] rounded-[3px] w-[20px] h-[20px] block"></span>
                      </div>
                      <span className="comm-custom-checkbox-text text-[#60809F] font-[inter-regular] text-[14px] not-italic font-normal leading-[normal]">
                        Auto Refresh
                      </span>
                    </div>
                  </div>
                  <div className="execution-sidebar-head-action w-[116px]">
                    <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                      Status
                    </label>
                    <Select
                      classNamePrefix="midform-select"
                      options={options2}
                    />
                  </div>
                </div>
              </div>
              <div className="execution-sidebar-body h-[calc(100vh_-_230px)] overflow-auto">
                <div className="execution-sidebar-items">
                  <div className="execution-sidebar-item execution-sidebar-item-pass relative py-[10px] px-[28px] cursor-pointer hover:bg-[#ecf0f4]">
                    <div className="execution-sidebar-rod execution-sidebar-rod-pass w-[5px] h-full bg-[#3EE092] absolute top-[0] left-[0]"></div>
                    <div className="execution-sidebar-item-inn">
                      <span className="block text-[#60809F] font-[inter-regular] text-[16px] not-italic font-normal leading-[normal] opacity-80 mb-[3px]">
                        14 May at 12:41:03
                      </span>
                      <span className="block text-[#60809F] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] opacity-80">
                        Succeeded in 0.019s
                      </span>
                    </div>
                  </div>
                  <div className="execution-sidebar-item execution-sidebar-item-fail relative py-[10px] px-[28px] cursor-pointer hover:bg-[#ecf0f4]">
                    <div className="execution-sidebar-rod execution-sidebar-rod-fail w-[5px] h-full bg-[#F04E61] absolute top-[0] left-[0] flex items-center justify-between"></div>
                    <div className="execution-sidebar-item-inn">
                      <span className="block text-[#60809F] font-[inter-regular] text-[16px] not-italic font-normal leading-[normal] opacity-80 mb-[3px]">
                        14 May at 12:41:03
                      </span>
                      <span className="block text-[#60809F] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] opacity-80">
                        Failed in 0.019s
                      </span>
                    </div>
                    <div className="re-execute-node w-[30px] h-[30px] cursor-pointer flex items-center justify-center">
                      <img
                        src="../../images/re-execute-node.svg"
                        alt="re-execute-node"
                        className="w-[14px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="header fixed top-[0] left-[80px] w-[calc(100%_-_80px)] h-[52px] z-10 bg-[#fff] border-b [border-bottom-style:solid] [border-bottom-color:#DCE4E8] flex items-center justify-between pl-[40px] pr-[40px]">
              <div className="header-left flex items-center justify-start">
                <div className="text-[#1A1C1E] font-[inter-bold] text-[18px] not-italic leading-[25.2px]">
                  {!editWorkFlowName && (
                    <div onClick={() => setEditWorkFlowName((prv) => !prv)}>
                      {workFlowName}
                    </div>
                  )}
                  {editWorkFlowName && (
                    <input
                    className="relative right-[11px] border border-solid border-[#DCE4E8] rounded-[6px] h-[35px] px-[10px] py-[0] hover:border-[#146DE0]"
                      type="text"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setWorkFlowName(e.target.value);
                      }}
                      value={workFlowName}
                    />
                  )}
                </div>
              </div>
              <div className="header-right flex items-center justify-end">
                <div className="workflow-switch node-comm-switch flex items-center justify-center flex-row-reverse">
                  <input
                    type="checkbox"
                    id="workflow-switch"
                    className="h-[0] w-[0] invisible"
                  />
                  <label
                    className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                    htmlFor="workflow-switch"
                  >
                    Toggle
                  </label>
                  <div className="switch-spans flex items-center justify-center mr-[7px]">
                    <span className="switch-span switch-span-1 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                      Inactive
                    </span>
                    <span className="switch-span switch-span-2 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                      Active
                    </span>
                  </div>
                </div>
                <button className="[transition:0.3s_all_ease] bg-[#E8F0FC] [box-shadow:none] ml-[24px] w-[36px] h-[36px] rounded-[6px] flex items-center justify-center">
                  <img src="../../images/header_share.svg" alt="header_share" />
                </button>
                <button
                  className="[transition:0.3s_all_ease] text-[#fff] bg-[#006DE0] [box-shadow:none] ml-[12px] w-[100px] h-[36px] rounded-[6px] border-0 !py-[0] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
                  onClick={async () => {
                    handelSaveWorkFlow();
                  }}
                >
                  Save
                </button>
                <button className="[transition:0.3s_all_ease] text-[#1A1C1E] border-[#DCE4E8] bg-[#fff] fill-[#292D32] hover:text-[#fff] hover:border-[#006DE0] hover:bg-[#006DE0] hover:fill-[#fff] [box-shadow:none] ml-[12px] w-[36px] h-[36px] rounded-[6px] border-0 !py-[0] font-[inter-medium] text-[16px] not-italic font-normal leading-[normal] border-[1px] border-solid flex items-center justify-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12.2916 15.8333C12.2916 17.1 11.2666 18.125 9.99992 18.125C8.73325 18.125 7.70825 17.1 7.70825 15.8333C7.70825 14.5667 8.73325 13.5417 9.99992 13.5417C11.2666 13.5417 12.2916 14.5667 12.2916 15.8333ZM8.95825 15.8333C8.95825 16.4083 9.42492 16.875 9.99992 16.875C10.5749 16.875 11.0416 16.4083 11.0416 15.8333C11.0416 15.2583 10.5749 14.7917 9.99992 14.7917C9.42492 14.7917 8.95825 15.2583 8.95825 15.8333Z" />
                    <path d="M12.2916 4.16536C12.2916 5.43203 11.2666 6.45703 9.99992 6.45703C8.73325 6.45703 7.70825 5.43203 7.70825 4.16536C7.70825 2.8987 8.73325 1.8737 9.99992 1.8737C11.2666 1.8737 12.2916 2.8987 12.2916 4.16536ZM8.95825 4.16536C8.95825 4.74036 9.42492 5.20703 9.99992 5.20703C10.5749 5.20703 11.0416 4.74036 11.0416 4.16536C11.0416 3.59036 10.5749 3.1237 9.99992 3.1237C9.42492 3.1237 8.95825 3.59036 8.95825 4.16536Z" />
                    <path d="M12.2916 10.0013C12.2916 11.268 11.2666 12.293 9.99992 12.293C8.73325 12.293 7.70825 11.268 7.70825 10.0013C7.70825 8.73464 8.73325 7.70964 9.99992 7.70964C11.2666 7.70964 12.2916 8.73464 12.2916 10.0013ZM8.95825 10.0013C8.95825 10.5763 9.42492 11.043 9.99992 11.043C10.5749 11.043 11.0416 10.5763 11.0416 10.0013C11.0416 9.4263 10.5749 8.95964 9.99992 8.95964C9.42492 8.95964 8.95825 9.4263 8.95825 10.0013Z" />
                  </svg>
                </button>
                <button
                  className="[transition:0.3s_all_ease] text-[#6C7278] border-[#DCE4E8] bg-[#fff] stroke-[#6C7278] hover:text-[#fff] hover:border-[#006DE0] hover:bg-[#006DE0] hover:stroke-[#fff] [box-shadow:none] h-[36px] rounded-[10px] border-0 !py-[0] fixed bottom-[25px] left-[0] right-[0] flex items-center justify-center max-w-[147px] mx-[auto] my-[0] border-[1px] border-solid"
                  onClick={() => editor?.runNodes()}
                >
                  <span className="font-[inter-medium] text-[14px] not-italic font-normal leading-[16px]">
                    Test Workflow
                  </span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-[8px]"
                  >
                    <path
                      d="M6.0901 13.2809H9.1801V20.4809C9.1801 22.1609 10.0901 22.5009 11.2001 21.2409L18.7701 12.6409C19.7001 11.5909 19.3101 10.7209 17.9001 10.7209H14.8101V3.52087C14.8101 1.84087 13.9001 1.50087 12.7901 2.76087L5.2201 11.3609C4.3001 12.4209 4.6901 13.2809 6.0901 13.2809Z"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="workflow-view-actions w-[177px] absolute left-[0] right-[0] m-auto -bottom-[18px] flex items-center justify-center">
                <div className="workflow-view-actions-inn p-[3px] bg-[#FFF] border-[1px] border-solid border-[#DCE4E8] rounded-[8px] flex items-center justify-center">
                  <button className="active border-0 !py-0 h-[28px] min-w-[79px] [box-shadow:none] px-[12px] rounded-[6px] text-[#1A1C1E] text-center font-[inter-medium] text-[12px] not-italic font-normal leading-[normal]">
                    Editor
                  </button>
                  <button className="ml-[3px] border-0 !py-0 h-[28px] min-w-[79px] [box-shadow:none] px-[12px] rounded-[6px] text-[#1A1C1E] text-center font-[inter-medium] text-[12px] not-italic font-normal leading-[normal]">
                    Executions
                  </button>
                </div>
              </div>

              <div
                className={`workflow-sidebar absolute right-[40px] top-[82px] z-10 flex flex-col items-end ${
                  isActive ? "active" : ""
                } ${isSideClosed ? "side_close" : ""}`}
                onMouseLeave={sideHandleMouseLeave}
                onMouseEnter={sideHandleHover}
              >
                <button
                  onClick={toggleNodes}
                  onMouseEnter={sideHandleHover}
                  className="workflow-add-nodes-action z-[1] [transition:0.3s_all_ease] text-[#1A1C1E] border-[#DCE4E8] bg-[#fff] fill-[#146DE0] hover:fill-[#fff] hover:text-[#fff] hover:border-[#006DE0] hover:bg-[#006DE0] hover:fill-[#fff] flex items-center justify-center w-[44px] h-[44px] [box-shadow:none] rounded-[6px] border-[1px] border-solid"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.92411 2.92411C-0.974704 6.82293 -0.974704 13.1771 2.92411 17.0759C6.82293 20.9747 13.1771 20.9747 17.0759 17.0759C20.9747 13.1771 20.9747 6.82293 17.0759 2.92411C13.1771 -0.974704 6.82293 -0.974704 2.92411 2.92411ZM14.005 9.24996C14.4154 9.24996 14.755 9.5896 14.755 10C14.755 10.2123 14.6701 10.3962 14.5356 10.5307C14.4012 10.6651 14.2172 10.75 14.005 10.75H10.75V14.005C10.75 14.2172 10.6651 14.4012 10.5307 14.5356C10.3962 14.6701 10.2123 14.755 10 14.755C9.5896 14.755 9.24996 14.4154 9.24996 14.005V10.75H5.99505C5.58465 10.75 5.245 10.4104 5.245 10C5.245 9.5896 5.58465 9.24996 5.99505 9.24996H9.24996V5.99505C9.24996 5.58465 9.5896 5.245 10 5.245C10.4104 5.245 10.75 5.58465 10.75 5.99505V9.24996H14.005Z" />
                  </svg>
                </button>

                <div className="workflow-sidebar-main flex flex-col items-end justify-start">
                  <div
                    className={`workflow-sidebar-inn z-[0] h-[calc(100vh_-_52px)] w-[596px] bg-[#FFF] fixed right-[0] top-[52px]`}
                  >
                    <div className="workflow-sidebar-header bg-[#b3d2f6] px-[26px] flex items-center justify-between h-[73px]">
                      <div className="global-popup-head-title text-[#1A1C1E] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[25.2px] opacity-80">
                        What happen next?
                      </div>
                      <button
                        className="global-popup-head-close [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
                        onClick={sideHandleCloseClick}
                      >
                        <img
                          src="../../images/close-popup.svg"
                          alt="popup-close"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="workflow-sidebar-wrap w-[596px] relative -right-[40px] top-0">
                    <div className="workflow-sidebar-search px-[30px] flex align-center justify-start border-b [border-bottom-style:solid] [border-bottom-color:#DCE4E8]">
                      <img
                        src="../../images/search-workflow-sidebar.svg"
                        alt="search-workflow-sidebar"
                      />
                      <input
                        type="text"
                        placeholder="Type here to search"
                        className="h-[55px] text-[14px] w-full pl-[12px] font-[inter-semibold]"
                      />
                    </div>
                    <div className="workflow-sidebar-nodes-parent">
                      <div className="workflow-sidebar-nodes-wrap px-[30px] pt-[0] pb-[20px] overflow-auto h-[calc(100vh_-_182px)]">
                        <div className="workflow-sidebar-nodes-container">
                          <div className="workflow-sidebar-nodes-container-title text-[#1A1C1E] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22.4px] mt-[24px] mb-[12px]">
                            Core Nodes
                          </div>
                          {editor.nodeContext.map((val, index) => {
                            return (
                              <button key={index}
                                onClick={() => {
                                  Addnodes(val, index);
                                }}
                                className="workflow-sidebar-item flex [transition:0.3s_all_ease] items-center justify-start w-full px-[20px] border-2 border-solid border-[#DCE4E8] hover:border-[#006DE0] rounded-[12px] mb-[12px]"
                              >
                                <div className="workflow-sidebar-item-inn flex items-center justify-start w-full h-[69px] border-b [border-bottom-style:solid] [border-bottom-color:#ebeff3]">
                                  <div className="workflow-sidebar-icn-wrap w-[36px] h-[36px] flex items-center justify-center mr-[16px] rounded-[100%] relative">
                                    <img
                                      src={val.nodeData.imgSrc}
                                      alt="sidebar-icn"
                                      className="relative z-[1]"
                                      width={32}
                                    />
                                    {/* <div className="workflow-sidebar-icn-hover-bg bg-[#006DE0] absolute w-full h-full opacity-0 rounded-[100%] z-0 top-[0] left-[0] [transition:0.3s_opacity_ease]"></div> */}
                                  </div>
                                  <div className="workflow-sidebar-item-text">
                                    <span className="block text-left text-[#1A1C1E] font-['inter-bold'] text-[16px] not-italic font-normal leading-[19.2px]">
                                      {val.nodeData.name}
                                    </span>
                                    <span className="block text-left text-[#6C7278] font-['inter-medium'] text-[14px] not-italic font-normal leading-[16.8px] mt-[6px]">
                                      {val.nodeData.description}
                                    </span>
                                  </div>
                                </div>
                              </button>
                            );
                          })}
                        </div>
                        <div className="workflow-sidebar-nodes-container">
                          <div className="workflow-sidebar-nodes-container-title text-[#1A1C1E] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22.4px] mt-[24px] mb-[12px]">
                            Custom Nodes
                          </div>

                          <button className="workflow-sidebar-item flex [transition:0.3s_all_ease] items-center justify-start w-full px-[20px] border-2 border-solid border-[#DCE4E8] hover:border-[#006DE0] rounded-[12px] mb-[12px]">
                            <div className="workflow-sidebar-item-inn flex items-center justify-start w-full h-[69px] border-b [border-bottom-style:solid] [border-bottom-color:#ebeff3]">
                              <div className="workflow-sidebar-icn-wrap w-[36px] h-[36px] flex items-center justify-center mr-[16px] rounded-[100%] relative">
                                <img
                                  src="../../images/gmail-icon.png"
                                  alt="sidebar-icn"
                                  className="relative z-[1]"
                                  width={32}
                                />
                              </div>
                              <div className="workflow-sidebar-item-text">
                                <span className="block text-left text-[#1A1C1E] font-['inter-bold'] text-[16px] not-italic font-normal leading-[19.2px]">
                                  Add label to message
                                </span>
                              </div>
                            </div>
                          </button>
                          <button className="workflow-sidebar-item flex [transition:0.3s_all_ease] items-center justify-start w-full px-[20px] border-2 border-solid border-[#DCE4E8] hover:border-[#006DE0] rounded-[12px] mb-[12px]">
                            <div className="workflow-sidebar-item-inn flex items-center justify-start w-full h-[69px] border-b [border-bottom-style:solid] [border-bottom-color:#ebeff3]">
                              <div className="workflow-sidebar-icn-wrap w-[36px] h-[36px] flex items-center justify-center mr-[16px] rounded-[100%] relative">
                                <img
                                  src="../../images/gmail-icon.png"
                                  alt="sidebar-icn"
                                  className="relative z-[1]"
                                  width={32}
                                />
                              </div>
                              <div className="workflow-sidebar-item-text">
                                <span className="block text-left text-[#1A1C1E] font-['inter-bold'] text-[16px] not-italic font-normal leading-[19.2px]">
                                  Add label to message
                                </span>
                              </div>
                            </div>
                          </button>
                          <button className="workflow-sidebar-item flex [transition:0.3s_all_ease] items-center justify-start w-full px-[20px] border-2 border-solid border-[#DCE4E8] hover:border-[#006DE0] rounded-[12px] mb-[12px]">
                            <div className="workflow-sidebar-item-inn flex items-center justify-start w-full h-[69px] border-b [border-bottom-style:solid] [border-bottom-color:#ebeff3]">
                              <div className="workflow-sidebar-icn-wrap w-[36px] h-[36px] flex items-center justify-center mr-[16px] rounded-[100%] relative">
                                <img
                                  src="../../images/gmail-icon.png"
                                  alt="sidebar-icn"
                                  className="relative z-[1]"
                                  width={32}
                                />
                              </div>
                              <div className="workflow-sidebar-item-text">
                                <span className="block text-left text-[#1A1C1E] font-['inter-bold'] text-[16px] not-italic font-normal leading-[19.2px]">
                                  Add label to message
                                </span>
                              </div>
                            </div>
                          </button>
                          <button className="workflow-sidebar-item flex [transition:0.3s_all_ease] items-center justify-start w-full px-[20px] border-2 border-solid border-[#DCE4E8] hover:border-[#006DE0] rounded-[12px] mb-[12px]">
                            <div className="workflow-sidebar-item-inn flex items-center justify-start w-full h-[69px] border-b [border-bottom-style:solid] [border-bottom-color:#ebeff3]">
                              <div className="workflow-sidebar-icn-wrap w-[36px] h-[36px] flex items-center justify-center mr-[16px] rounded-[100%] relative">
                                <img
                                  src="../../images/gmail-icon.png"
                                  alt="sidebar-icn"
                                  className="relative z-[1]"
                                  width={32}
                                />
                              </div>
                              <div className="workflow-sidebar-item-text">
                                <span className="block text-left text-[#1A1C1E] font-['inter-bold'] text-[16px] not-italic font-normal leading-[19.2px]">
                                  Add label to message
                                </span>
                              </div>
                            </div>
                          </button>
                          <button className="workflow-sidebar-item flex [transition:0.3s_all_ease] items-center justify-start w-full px-[20px] border-2 border-solid border-[#DCE4E8] hover:border-[#006DE0] rounded-[12px] mb-[12px]">
                            <div className="workflow-sidebar-item-inn flex items-center justify-start w-full h-[69px] border-b [border-bottom-style:solid] [border-bottom-color:#ebeff3]">
                              <div className="workflow-sidebar-icn-wrap w-[36px] h-[36px] flex items-center justify-center mr-[16px] rounded-[100%] relative">
                                <img
                                  src="../../images/gmail-icon.png"
                                  alt="sidebar-icn"
                                  className="relative z-[1]"
                                  width={32}
                                />
                              </div>
                              <div className="workflow-sidebar-item-text">
                                <span className="block text-left text-[#1A1C1E] font-['inter-bold'] text-[16px] not-italic font-normal leading-[19.2px]">
                                  Add label to message
                                </span>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="canvas_zoom_actions fixed bottom-[30px] left-[110px] bg-[#fff] flex flex-col justify-center items-center z-[1] rounded-[6px] overflow-hidden border [border-style: solid] [border-color: #D1D4D9]">
                <button className="canvas_zoom_action canvas_zoom_in w-[36px] h-[36px] flex justify-center items-center border-b [border-bottom-style: solid] [border-bottom-color: #D1D4D9]">
                  <img src="../../images/canvas_plus.svg" alt="canvas_plus" />
                </button>
                <button className="canvas_zoom_action canvas_zoom_out w-[36px] h-[36px] flex justify-center items-center">
                  <img src="../../images/canvas_minus.svg" alt="canvas_minus" />
                </button>
              </div>
            </div>
          </div>
        )}
        {contextHolder}
        <div ref={ref} style={{ height: "100vh", width: "100vw" }}></div>
      </div>
    </CommonLayout>
  );
}
