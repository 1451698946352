import { ClassicPreset } from "rete";
import { DataflowEngine, DataflowNode } from "rete-engine";
import { Schemes } from "../rete/types";
import { ActionSocket, TextSocket, copyNodeWithConnections, logMessage } from "../editor";
import { io } from "socket.io-client";
import { URLS } from "../utils";
const socket = new ClassicPreset.Socket("socket");
class ScheduleTrigger
  extends ClassicPreset.Node<
    {},
    {
      output: ClassicPreset.Socket;
    },
    {
      triggerIntervalData: ClassicPreset.InputControl<"text">;
    }
  >
  implements DataflowNode
{
  width = 600;
  height = 400;
  outputData: any;
  response: any;
  isNodeEnable=true
  isEntryNode=true
  nodeData={
    imgSrc:"../../images/trigger-node-icon.svg",
    name: "Schedule Trigger", 
    description:"Schedule Trigger"
  }
  constructor(
    private dataflow: DataflowEngine<Schemes>,
    public onClick: (jsonData: any) => void,
    public log: (text: string) => void,
    public editor 
  ) {
    super("Schedule Trigger");

    this.addControl(
      "triggerIntervalData",
      new ClassicPreset.InputControl("text", {})
    );
    this.addOutput(
      "output",
      new ClassicPreset.Output(new TextSocket(), "Output")
    );
  }
  dupicateNode(){
    copyNodeWithConnections(this.id)
  }
  setControlValue(controlName: string, value: any) {
    const control = this.controls[controlName];
    
    if (control) {
      control.value = value; // Set the value of the control
      console.log(`Set ${controlName} to:`, value);
       
      // Optionally trigger an update on the editor to reflect the new value
     // this.editor.trigger('process');
    } else {
      console.error(`Control ${controlName} not found`);
    }
  }
  data(): { output: any } {
    return {
      output:this.outputData,
    };
  }  
   
  async execute(input: "exec", forward: (output: "output",data:any) => void) {   
    if(this.isNodeEnable){
      const socket = io(URLS.BACK_END); // Connect to socket
      socket.on("schdule-trigger", (e) =>{  
        try {
          
          console.log('first schdule-trigger')  
          this.outputData=e
          console.log("Connected to server");
          forward("output",new ClassicPreset.Output(new TextSocket(), JSON.stringify(e)))
          logMessage(`Node  executed successfully`);
          console.log(this.editor)
          
        } catch (error) {
          logMessage(`Error executing node : ${error.message}`);
          console.log(this.editor)
        } 
       // if(e.userId==='6618bfb8ebc89958d978546d'){
        
       // }
      }); 
    }
  }
}

export default ScheduleTrigger;
