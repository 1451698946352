import * as React from "react";
import styled, { css } from "styled-components";
import { ClassicScheme, RenderEmit, Presets } from "rete-react-plugin";
import { Drag } from "rete-react-plugin";
import Switch from "react-switch";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import HttpNode from "../customization/Httpnode";
import { Schemes } from "../rete/types";
import DynamicKeyValueInput from "./dynamicInput";
import DynamicKeyValueInputPagenation from "./dynamicinputPagination";
import WebHookTrigger from "../customization/webhookTrigger";
import io from "socket.io-client";
import { useEffect } from "react";
import { sleep, URLS } from "../utils";
import createAPI from "../api";
import WebhookPopUpUI from "./WebHookPopupUI";
import Popup from "reactjs-popup"; 
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import DynamicTable from "../components/Dynamictable";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import RecursiveComponent from "../components/RecursiveComponent";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Formik,
  Form, 
  ErrorMessage, 
  FormikProps, 
} from "formik";
import * as Yup from "yup";
import JSONInputWrapper from "../components/JSONInputWrapper";
import { 
  deleteNodes, 
  runNodes,
} from "../editor";
import { toast } from "react-toastify";
const { RefSocket, RefControl } = Presets.classic;
export const $nodewidth = 200;
export const $socketmargin = 6;
export const $socketsize = 16;

type NodeExtraData = { width?: number; height?: number };
type Props<S extends ClassicScheme> = {
  data: WebHookTrigger & NodeExtraData;
  emit: RenderEmit<S>;
};
export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #d2dbe4;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  cursor: pointer;
  box-sizing: border-box;
  // width: ${(props) =>
    Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  width: 166px;
  height: 166px;
  padding-bottom: 6px;
  position: relative;
  user-select: none;
  // &:hover {
  //   background: #333;
  // }
  &:focus {
    outline: 8px solid #dfe5ec;
  }
  ${(props) =>
    props.selected &&
    css`
      outline: 8px solid #dfe5ec;
    `}
  .title {
    color: white;
    font-family: sans-serif;
    font-size: 18px;
    padding: 8px;
  }
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    display: block;
    position: absolute;
    right: -24px;
    height: 24px;
    width: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    border-radius: 100%;
    background: #a5adba;
  }
  .input-socket {
    text-align: left;
    margin-left: -1px;
    display: inline-block;
  }
  .input-title,
  .output-title {
    display: none;
    vertical-align: middle;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }

  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .sc-jsJBEP.ciTnqI {
    height: auto !important;
    width: 250px !important;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }
  .disabled-node {
    opacity: 0.5;
    pointer-events: none; /* Prevents interaction */
  }
  ${(props) => props.styles && props.styles(props)}
`;
interface FormValues {
  url: string;
  path: string;
  method: string;
  body: Object;
}
export function WebhookComponent(props: Props<Schemes>) {
  const API = createAPI();
  const [webhookData, setWebhookData] = React.useState({
    url: props.data.controls.url.value + "/" + props.data.controls.path.value,
    path: props.data.controls.path.value,
    method: props.data.controls.method.value,
  });
  const [runbuttonText, setRunbuttonText] = React.useState("Listen for Event");
  const [isListening, setIsListening] = React.useState(false);
  const [showpopUp, setShowpopUp] = React.useState(false);
  const [editOutputJson, setEditOutputJson] = React.useState(false);
  const selected = props.data.selected || false;
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const controls = Object.entries(props.data.controls);
  const { id, width, height } = props.data;
  const [popup, setPopup] = React.useState(null);
  const [isNodeEnable, setIsNodeEnable] = React.useState(true);
  const [tabIndexOutput, setTabIndexOutput] = React.useState(0);
  const [isValidJson, setIsValidJson] = React.useState(true);
  const [outputData, setOutputData] = React.useState([ ]);
  const formikRef = React.useRef<FormikProps<FormValues>>(null);
  const submitButtonRef = React.useRef(null);
  const [isLableEdit, setIsLableEdit] = React.useState(false);
  const [label, setLabel] = React.useState(props.data.label);
  useEffect(() => {
    const socket = io(URLS.BACK_END); // Connect to socket
    socket.on("webhookTrigger", async (e) => {
      console.log(e.uniqueId, webhookData.path);
      if (e.uniqueId === webhookData.path) { 
        props.data.httpData=JSON.stringify(e.responce)
      //  await sleep(10000) 
       
        setOutputData(e.responce);
        API.post("rete/webhook/start-stop-webhook", {
          webhookPath: webhookData.path,
          isStarted: false,
          method:webhookData.method
        },
        {
          headers: {
            "Requires-Auth": true, // This tells the interceptor to add the auth token
          },
        }
      ).then((data) => {
          
          setIsListening(data.data.webhookJson.isStarted);
        //  props.data.reExecuteData()
          //props.data.editor.process(props.data.id);
          runNodes();
          if (data.data.webhookJson.isStarted) {
            props.data.log("Data received");
            setRunbuttonText("Stop listening");
          } else {
            setRunbuttonText("Listen for Event");
          }
        });
      }
    });
    // Return a cleanup function
    return () => {
      socket.disconnect(); // Disconnect socket when the component unmounts
      console.log("Disconnected from server");
    };
  }, []);
  useEffect(() => {
    console.log(props.data.httpData);
    /*  setOutputData(JSON.parse(props.data.httpData)) */
  }, [props.data.httpData]);
  useEffect(() => {
    let data = API.get(`rete/webhook/get-status/${webhookData.path}`).then(
      (data) => {
        console.log(data.data.isListing);
        setIsListening(data.data.isListing);
        if (data.data.isListing) {
          setRunbuttonText("Stop listening");
        } else {
          setRunbuttonText("Listen to webhook");
        }
      }
    );
  }, []);

  async function handelWebHook() {
    setRunbuttonText("Stop listening");
    /* if (formikRef.current) {
      formikRef.current.submitForm();
    } */
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
   //runNodes();
    console.log(webhookData);
    API.post("rete/webhook/start-stop-webhook", {
      webhookPath: webhookData.path,
      isStarted: !isListening,
      method:webhookData.method
    },
    {
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    }
  ).then((data) => {
      setIsListening(data.data.webhookJson.isStarted);
      if (data.data.webhookJson.isStarted) {
        setRunbuttonText("Stop listening");
      } else {
        setRunbuttonText("Listen for Event");
      }
    });
  }
  const closeModal = () => {
    setShowpopUp(false);
    toggleVisibility(false);
  };
  const toggleVisibility = (show) => {
    const actions = document.querySelector(
      ".workflow-view-actions-inn"
    ) as HTMLElement | null;
    const sidebar = document.querySelector(
      ".workflow-sidebar"
    ) as HTMLElement | null;
    if (show) {
      if (actions) {
        actions.style.display = actions.style.display === "none" ? "" : "none";
      }
      if (sidebar) {
        sidebar.style.display = sidebar.style.display === "none" ? "" : "none";
      }
    } else if (!show) {
      if (actions) {
        actions.style.display = "";
      }
      if (sidebar) {
        sidebar.style.display = "";
      }
    }
  };
  const options = [
    { value: "GET", label: "GET" },
    { value: "POST", label: "POST" },
    { value: "DELETE", label: "DELETE" },
    { value: "GET-1", label: "HEAD" },
    { value: "PATCH", label: "PATCH" },
    { value: "PUT", label: "PUT" },
  ];
  const [selectedOption, setSelectedOption] = React.useState(null);
  function handelJsonedit() {
    setEditOutputJson((prev) => !prev);
    setTabIndexOutput(1);
  }
  function HandelOnselectTab(index) {
    setTabIndexOutput(index);
    setEditOutputJson(false);
  }
  const validationSchema = Yup.object({
    url: Yup.string().required("URL is required"),
    path: Yup.string().required("Path is required"),
    method: Yup.string().required("HTTP Method is required"),
    body: Yup.string(),
  });
  function handelSaveOutputJson() {
    if (isValidJson) {
      props.data.httpData = outputData;
      setEditOutputJson(false);
    }
  }
  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setOutputData(data.jsObject);
      setIsValidJson(true);
    } else {
      // If data.jsObject is undefined, the JSON is invalid
      setIsValidJson(false);
    }
  };

  function handelDeleteNode() {
    deleteNodes(props.data.id);
  }
  function handelEnableDisableNode() {
    console.log("enable disable");
    setIsNodeEnable((prv) => !prv);
    props.data.isNodeEnable = isNodeEnable;
  }
  function handelCopyNode() {
    props.data.dupicateNode();
  }
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(webhookData.url)
      .then(() => {
        //alert('Copied to clipboard!');
        toast("Url copied to clipboard", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
       // props.data.log("Url copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const handleDrop = (item) => {
    console.log("Dropped item:", item);
    // Update your form state here with the dropped item
  };
  function handelLableEdit() {
    setIsLableEdit((prv) => !prv);
    setLabel(props.data.label);
  }

  function handelSaveLableEdit() {
    setIsLableEdit(false);
    props.data.label = label
  }
  const handleChangeLable = (e) => {
    setLabel(e.target.value);
    // If you need to update the parent component or props.data, you should call a function passed as a prop
    /* props.data.label = e.target.value; */
  };
  return (
    <>
      <Popup
        open={showpopUp}
        className="webhook-node-popup"
        onClose={closeModal}
      >
        <div className="modal">
          <div className="node-popup webhook-node-popup fixed top-[70px] left-[0] w-full bg-[#F4F1FA] h-[calc(100%_-_70px)] z-[11]">
            <div className="node-popup-inn">
              <div className="node-popup-back border-b [border-bottom-style:solid] [border-bottom-color:#BECBD8] h-[50px] bg-[#eee] px-[29px] py-[0] flex items-center justify-start">
                <button
                  onClick={closeModal}
                  className="flex items-center justify-center border-[0] p-0 h-auto rounded-none bg-transparent [box-shadow:none]"
                >
                  <img src="../../images/node-popup-back.svg" alt="node-popup-back" />
                  <span className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 text-[#000] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] ml-[4px]">
                    Back to Canvas
                  </span>
                </button>
              </div>
              <div className="node-popup-views flex h-[calc(100vh_-_120px)]">
                <div className="node-popup-view node-popup-input-view [flex-basis:33.33%] w-1/3">
                  <div
                    className={`listen-event-block flex flex-col items-center justify-center h-full px-[45px] py-[0] `}
                  >
                    <div
                      className={`comm-node-pulse webhook-node-pulse flex flex-col items-center justify-center w-full ${
                        !isListening ? "hidden" : ""
                      }`}
                    >
                      <div className="comm-node-pulse-anime relative w-[175px] h-[175px] flex items-center justify-center">
                        <div className="comm-node-pulse-wave absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2">
                          <div className="comm-node-pulse-icon flex items-center justify-center w-[74px] h-[74px] rounded-[50%] text-[#FFF] text-[20px] text-center leading-[100px] font-[sans-serif] uppercase animate-[webhook-pulse_3s_linear_infinite] cursor-pointer">
                            <img
                              src="../../images/webhook-node-icon.svg"
                              alt="webhook-node-icon"
                              className="w-[40px]"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="comm-node-pulse-input w-full mb-[40px]">
                        <div className="comm-node-pulse-input-title font-[inter-medium] text-[18px] leading-[normal] mt-[20px] text-[#006DE0] text-center">
                          Listening for test event
                        </div>
                        <div className="comm-node-pulse-input-label text-center font-[inter-regular] text-[16px] text-[rgba(0,_0,_0,_0.5)] mt-[10px] mb-[10px]">
                          Make a {webhookData.method=='GET-1'?'Head':webhookData.method} request to:
                        </div>
                        <div className="comm-node-pulse-input-parent relative">
                          <input
                            value={webhookData.url}
                            type="text"
                            className=" w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[rgba(0,_0,_0,_0.5)] font-[inter-regular] text-[14px] not-italic font-normal leading-[normal]"
                          />
                          <span
                            className="absolute right-px top-[0] h-[34px] bg-[#fff] text-[rgba(0,_0,_0,_0.5)] bottom-[0] m-auto rounded-[5px] leading-[34px] px-[10px] py-[0] text-[12px] cursor-pointer opacity-0 z-[1]"
                            onClick={handleCopyClick}
                          >
                            Click to copy
                          </span>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={handelWebHook}
                      className={`[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 px-[12px] py-0 h-[34px] rounded-[4px] bg-[#006DE0] text-[#FFF] font-['inter-regular'] ${
                        isListening ? "node-popup-button-listening" : ""
                      } text-[12px] not-italic font-normal leading-[normal] mb-[37px]`}
                    >
                      {runbuttonText}
                    </button>
                    {/* <p className="text-[#000] text-center font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal]">
                      Once you’ve finished building your workflow, run it
                      without having to click the button by using the production
                      webhook URL.
                    </p> */}
                  </div>
                </div>
                <div className="node-popup-view node-popup-fields-view [flex-basis:33.33%] w-1/3 bg-[#fff]">
                  <div
                    className={`node-popup-midform ${
                      isListening ? "node-popup-midform-listening" : ""
                    }`}
                  >
                    <Formik
                      innerRef={formikRef}
                      initialValues={{
                        url: props.data.controls.url.value,
                        path: props.data.controls.path.value,
                        method: props.data.controls.method.value,
                        body: {},
                      }}
                      validationSchema={validationSchema}
                      validateOnChange={true} // Validates form on each field's onChange event
                      validateOnBlur={true}
                      onSubmit={(values, { setSubmitting }) => {
                        /*  alert(JSON.stringify(values, null, 2)); */
                        setSubmitting(false);
                        setWebhookData(values);
                        console.log(values);
                     //   runNodes();
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        setFieldValue,
                      }) => (
                        <Form>
                          <div className="midform-head p-[22px] pt-[13px]">
                          <div className="midform-head-wrap relative">
                          {isLableEdit && (
                                    <div className="edit-comm-name absolute z-[3] top-2/4 left-[200px] w-[200px] -translate-y-1/2 bg-[#fff] rounded-[4px] [box-shadow:0_0_15px_-5px_rgba(0,_0,_0,_0.25)] p-[10px]">
                                      <>
                                        <div className="edit-comm-name-title font-[inter-medium] text-[14px] leading-[normal] text-[rgba(0,0,0,0.8)] font-normal mb-[10px]">Rename node</div>
                                        <input
                                          type="text"
                                          onChange={handleChangeLable}
                                          value={label}
                                          className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                        />
                                        <div className="flex items-center justify-end mt-[10px]">
                                        <button
                                          className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                          onClick={handelLableEdit}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#006DE0] rounded-[4px]"
                                          onClick={handelSaveLableEdit}
                                        >
                                          Save
                                        </button>
                                        </div>
                                        <span className="edit-comm-name-diamond w-[10px] h-[10px] bg-[#fff] rotate-45 absolute top-[0] bottom-[0] m-auto -left-[5px]"></span>
                                      </>
                                    </div>
                                  )}
                            <div className="midform-head-inn relative flex items-center justify-between px-[19px] py-[15px]">
                              <div className="midform-head-title relative z-[2] flex items-center justify-start">
                                <img
                                  src="../../images/node-popup-webhook.svg"
                                  alt="node-popup-webhook"
                                />
                                <span
                                  onClick={handelLableEdit}
                                  className="ml-[8px] text-[#60809F] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[normal]"
                                  title={props.data.label}
                                >
                                  {props.data.label}                                  
                                </span>
                              </div>
                              <button
                                ref={submitButtonRef}
                                type="button"
                                onClick={() => {
                                  let updateData = {
                                    ...values,
                                    url: values.url + "/" + values.path,
                                  };
                                  setWebhookData(updateData);
                                  handelWebHook();
                                }}
                                className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[34px] rounded-[4px] bg-[#006DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                              >
                                Test Workflow
                              </button>
                              <div className="midform-head-bg absolute w-full h-full top-[0] left-[0] z-0 bg-[linear-gradient(172deg,_rgba(30,_7,_95,_0.70)_6.32%,_#006DE0_93.51%)] rounded-[10px] opacity-[0.09]"></div>
                            </div>
                          </div>
                          </div>
                          <div className="midform-fields">
                            <div className="midform-field mb-[30px] px-[45px]">
                              <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                URL
                              </label>
                              <input
                                type="text"
                                className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] bg-[#f7f7f7] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                readOnly
                                name="url"
                                value={`${values.url}/${values.path}`}
                              />
                              <ErrorMessage className="error" name="url" component="div" />
                            </div>
                            <div className="midform-field midform-field-select relative mb-[30px] px-[45px]">
                              {/* <div className="midform-field-actions absolute -top-[6px] right-[45px] flex items-center justify-start z-[1]">
                                <button className="midform-field-action midform-field-action-dots mr-[5px] w-[20px] h-[20px] flex items-center justify-center cursor-pointer rounded-[100%] hover:bg-[#EEEEEE]">
                                  <img
                                    src="../../images/midform-3dots.svg"
                                    alt="midform-3dots"
                                  />
                                </button>
                                <div className="midform-field-action-wrap bg-[#EEEEEE] rounded-[4px] flex items-center justify-center">
                                  <button className="midform-field-action midform-field-action-fixed px-[6px] py-[4px] rounded-[4px] text-[rgba(53,53,53,0.7)] font-['inter-regular'] text-[10px] not-italic font-normal leading-[normal]">
                                    Fixed
                                  </button>
                                  <button className="midform-field-action midform-field-action-express px-[6px] py-[4px] rounded-[4px] text-[rgba(53,53,53,0.7)] font-['inter-regular'] text-[10px] not-italic font-normal leading-[normal]">
                                    Expression
                                  </button>
                                </div>
                              </div> */}
                              <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                HTTP Method
                              </label>
                              <Select
                                name="method"
                                className="midform-select-main"
                                classNamePrefix="midform-select"
                                /*  defaultValue={{ value: values.method, label: values.method }} */
                                defaultValue={options.find(
                                  (option) => option.value === values.method
                                )}
                                onChange={(option) =>{
                                  setFieldValue("method", option.value)
                                  let data={...webhookData,method:option.value}
                                  setWebhookData(data)
                                  props.data.controls.method.value=option.value
                                }}
                                options={options}
                              />
                              <ErrorMessage name="method" className="error" component="div" />
                            </div>
                            <div className="midform-field mb-[30px] px-[45px]">
                              <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                Path
                              </label>
                              <input
                                value={`${values.path}`}
                                onChange={(e)=>{
                                  handleChange(e)
                                   props.data.controls.path.value=e.target.value
                                  }}
                                name="path"
                                type="text"
                                className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                              />
                              <ErrorMessage name="path" className="error" component="div" />
                            </div>
                            {/* {values.method !== "GET" && (
                              <div className="midform-field mb-[30px] px-[45px]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Body
                                </label>
                                <JSONInputWrapper
                                  fieldName={"body"}
                                  setFieldValue={setFieldValue}
                                  jsonData={values.body}
                                />
                                <ErrorMessage name="path" className="error" component="div" />
                              </div>
                            )} */}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="node-popup-view node-popup-output-view [flex-basis:33.33%] w-1/3">
                  <div className="node-popup-output-view-inn px-[30px] py-[26px]">
                    <div className="node-popup-output-tabs">
                      <Tabs
                        selectedIndex={tabIndexOutput}
                        onSelect={(index) => HandelOnselectTab(index)}
                      >
                        <div className="node-popup-output-tabs-wrap flex items-center justify-between mb-[26px]">
                          <div className="node-popup-output-title text-[#006DE0] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[normal] tracking-[1.92px] uppercase">
                            Output
                          </div>
                          <TabList className="flex items-center justify-end">
                            {!editOutputJson ? (
                              <>
                                <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                  <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                    Table
                                  </Tab>
                                  <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                    JSON
                                  </Tab>
                                  <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                    Schema
                                  </Tab>
                                </div>
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center ml-[8px] rounded-[4px] bg-[#fff] w-[36px] h-[36px]"
                                  onClick={handelJsonedit}
                                >
                                  <img
                                    src="../../images/node-popup-edit.svg"
                                    alt="node-popup-edit"
                                  />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                  onClick={handelJsonedit}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#006DE0] rounded-[4px]"
                                  onClick={handelSaveOutputJson}
                                  disabled={!isValidJson}
                                >
                                  Save
                                </button>
                              </>
                            )}
                          </TabList>
                        </div>
                        <div className="node-popup-output-tab-panels">
                          <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                            <div className="node-table-view-wrap">
                              <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_235px)]">
                                <DynamicTable data={outputData} />
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel
                            className={`node-popup-output-tab-panel node-json-view  webhook-node-json-view h-[calc(100vh_-_235px)] ${
                              editOutputJson ? "" : "node-json-view-readonly"
                            }`}
                          >
                            <JSONInput
                              id="webhook-json"
                              placeholder={outputData}
                              locale={locale}
                              theme="light_mitsuketa_tribute"
                              colors={{
                                default: "#39ADB5",
                                keys: "#E53935",
                                string: "#91B859",
                                number: "#F76D47",
                                background: "#F7F7F7",
                              }}
                              width={"100%"}
                              height={"100%"}
                              style={{
                                outerBox: {
                                  borderRadius: "6px",
                                  border: "1px solid #CCC",
                                  overflow: "hidden",
                                  background: "#F7F7F7",
                                },
                              }}
                              onChange={handleJsonChange}
                            />
                          </TabPanel>
                          <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                            <div className="node-schema-tree-view webhook-node-schema-tree-view">
                            <DndProvider backend={HTML5Backend}>
                              {outputData.map((item, index) => (
                                <RecursiveComponent
                                  key={index}
                                  item={item}
                                  onFieldDrop={handleDrop}
                                  draggable={false}
                                />
                              ))}
                               </DndProvider>
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>

      <NodeStyles
        selected={selected}
        width={166}
        height={166}
        className={`nodeWrap node-comm node-webhook ${
          isNodeEnable ? "" : "disabled-node"
        } `}
        data-testid="node"
      >
        <div
          className={`title ${isNodeEnable ? "" : "disabled-node"}`}
          data-testid="title"
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            setShowpopUp((pop) => !pop);
            toggleVisibility(true);
            e.stopPropagation();
          }}
        >
          <div className="node-block-parent">
            <div className="node-block flex items-center justify-center flex-col">
              <div className="node-block-actions absolute -top-[39px] right-[0] flex items-center justify-end z-[1] opacity-0 scale-0 w-[0] h-[0] [transition:0.3s_opacity_ease] pb-[14px]">
                <button
                  onClick={handelEnableDisableNode}
                  className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-power.svg"
                    alt="node-action-power"
                  />
                </button>
                <button
                  onClick={handelCopyNode}
                  className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-copy.svg"
                    alt="node-action-copy"
                  />
                </button>
                <button
                  onClick={handelDeleteNode}
                  className="cursor-pointer opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-delete.svg"
                    alt="node-action-delete"
                  />
                </button>
              </div>
              <div className="node-block-icon-parent w-[70px] h-[70px] flex items-end justify-center">
                <img
                  className="node-block-icon"
                  src="../../images/webhook-node-icon.svg"
                  alt="webhook-node-icon"
                />
              </div>
              <span className="node-block-title mt-[11px] text-[#60809F] text-center font-[inter-medium] text-[16px] not-italic font-normal leading-[normal]" title={props.data.label}>
                {props.data.label}
              </span>
            </div>
          </div>
        </div>

        {outputs.map(
          ([key, output]) =>
            output && (
              <div className="output" key={key} data-testid={`output-${key}`}>
                <div className="output-title" data-testid="output-title">
                  {output?.label}
                </div>
                <RefSocket
                  name="output-socket"
                  side="output"
                  emit={props.emit}
                  socketKey={key}
                  nodeId={id}
                  payload={output.socket}
                />
                <div className="node-block-plus-actions flex items-center justify-center absolute left-[185px] top-[0] bottom-[0] m-auto flex items-center justify-start h-[34px] leading-0">
                  <div className="node-block-plus-actions-arrow flex items-center justify-center relative">
                    <div className="node-block-plus-actions-arrow-line bg-[#919394] h-[2px] w-[105px]"></div>
                    <div className="node-block-plus-actions-arrow-text text-[#60809F] text-center font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] absolute left-[0] right-[0] m-auto block">
                      <span className="bg-[#F2F5F7] px-[3px] py-[0] uppercase">{webhookData.method=='GET-1'?'Head':webhookData.method}</span>
                    </div>
                  </div>
                  <button className="node-block-plus-actions-btn w-[34px] h-[34px]">
                    <img src="../../images/plus-icon-node.svg" alt="plus-icon-node" />
                  </button>
                </div>
              </div>
            )
        )}
      </NodeStyles>
    </>
  );
}
