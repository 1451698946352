import { Link } from "react-router-dom";
import DotsDropdown from "../../components/DotsDropdown";
import CommonLayout from "../../components/view/common-layout";
import CommonHeader from "../../components/view/common-header";
import { useEffect, useState } from "react";
import createAPI from "../../api";
import { formatWorkflowInfo } from "../../utils";

const WorkFlow = () => {
  const API = createAPI();

  const [workFlow, setWorkFlow] = useState([]);
  const dotoptions = [
    {
      label: "Reset Value",
      onClick: () => console.log("Reset Value clicked"),
    },
    {
      label: "Fixed",
      onClick: () => console.log("Fixed clicked"),
    },
    {
      label: "Another Option",
      onClick: () => console.log("Another Option clicked"),
    },
    useEffect(()=>{
      API.get('rete/get',{
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      }).then(data =>{
        console.log(data)
        setWorkFlow(data.data.workFlows);
      })
      
    },[])
  ];
  const iconSrc = "../../images/workflow-dots.svg";
  return (
    <CommonLayout>
      <div className="workflow-listing w-[calc(100%_-_80px)] h-screen ml-auto bg-[#F2F5F7]">
        <CommonHeader />
        <div className="common-module-list px-[40px]">
          <div className="common-module-list-head flex items-center justify-between py-[25px]">
            <div className="workflow-listing-head-title text-[#1C1E21] font-['inter-bold'] text-[20px] not-italic font-normal leading-[normal]">
              My Workflows
            </div>
            <Link
              to="/home/workflow/new"
              className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 w-[206px] h-[48px] [box-shadow:none] bg-[#006DE0] text-[#FFF] font-[inter-semibold] text-[16px] not-italic font-normal leading-[normal] !py-0 rounded-[4px]"
            >
              <img
                src="../../images/add-node-plus.svg"
                alt="add-node-plus"
                className="mr-[6px]"
              />
              <span>Add Workflow</span>
            </Link>
          </div>
          <div className="common-module-list-head-body bg-[#fff]">
            <div className="common-module-list-actions p-[18px] pb-0">
              <div className="common-module-list-actions-wrap border-b [border-bottom-style:solid] [border-bottom-color:#DCE4E8] flex items-start justify-between pb-[18px]">
                <div className="common-module-list-action-search flex align-center justify-start border [border-style:solid] [border-color:#DCE4E8] rounded-[6px] px-[14px] w-[267px]">
                  <img src="../../images/credentials-dropdown-search.svg" alt="credentials-dropdown-search" width={13} />
                  <input type="text" placeholder="Search workflow" className="text-[#1C1E21] h-[32px] text-[12px] w-full pl-[10px] font-[inter-medium]" />
                </div>

                <div className="common-module-list-action-buttons flex items-start justify-end">
                  <div className="common-module-list-action-button common-module-list-action-button-filter cursor-pointer w-[92px] h-[32px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]">
                    <img src="../../images/filter-button-icon.svg" alt="filter-button-icon" width={19} />
                    <span className="font-[inter-semibold] text-[11px] text-[#1C1E21] ml-[4px]">Filter</span>
                  </div>
                  <div className="common-module-list-action-button common-module-list-action-button-sortby ml-[8px] cursor-pointer w-[92px] h-[32px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]">
                    <img src="../../images/sort-by-icon.svg" alt="sort-by-icon" width={19} />
                    <span className="font-[inter-semibold] text-[11px] text-[#1C1E21] ml-[4px]">Sort By</span>
                  </div>
                  <div className="common-module-list-action-button common-module-list-action-button-group ml-[8px] cursor-pointer h-[32px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px] px-[3px]">
                    <div className="common-module-list-action-button-group-block h-[24px] font-[inter-semibold] bg-[#6A737D] text-[12px] px-[12px] rounded-[6px] leading-[24px] text-[#fff] active">By me</div>
                    <div className="common-module-list-action-button-group-block h-[24px] font-[inter-regular] text-[12px] px-[12px] rounded-[6px] leading-[24px] text-[#1A1C1E]">Team</div>
                    <div className="common-module-list-action-button-group-block h-[24px] font-[inter-regular] text-[12px] px-[12px] rounded-[6px] leading-[24px] text-[#1A1C1E]">Shared</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="common-module-list-rows p-[18px] pb-[4px] max-h-[calc(100vh_-_233px)] overflow-auto">
              <div className="common-module-list-rows-wrap ">
                {workFlow.map((val,key)=>{
                  return (<div key={key} className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]"><Link to={`/home/workflow/${val.id}`}>{val.title}</Link></div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">{formatWorkflowInfo(val)} </div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">
                    <div className="common-module-list-row-avatars mr-[16px] flex items-center justify-center">
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar-count border-2 [border-style:solid] [border-color:#fff] rounded-[100%] bg-[#EBEEF1]">
                        <div className="w-[32px] h-[32px] flex items-center justify-center font-[inter-bold] text-[13px] tracking-[0.53px]">+2</div>
                      </div>
                    </div>
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Created by me
                    </div>
                    <div className="workflow-switch node-comm-switch flex items-center justify-center mr-[16px]">
                      <input
                        type="checkbox"
                        id="workflow-switch"
                        className="h-[0] w-[0] invisible"
                      />
                      <label
                        className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative mr-[6px]"
                        htmlFor="workflow-switch"
                      >
                        Toggle
                      </label>
                      <div className="switch-spans flex items-center justify-center">
                        <span className="switch-span switch-span-1 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Inactive
                        </span>
                        <span className="switch-span switch-span-2 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Active
                        </span>
                      </div>
                    </div>
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <img src="/images/common-module-dots.svg" alt="common-module-dots" />
                    </div>
                  </div>
                </div>)
              })}
                
              </div>
            </div>
            {/* <div className="workflow-listing-items">
              <div className="workflow-listing-item flex items-start justify-between [box-shadow:0px_8px_10.1px_rgba(191,_204,_217,_0.46)] mb-[16px] bg-[#fff] rounded-[6px] px-[30px] pt-[22px] pb-[18px]">
                <div className="workflow-listing-item-text">
                  <div className="workflow-listing-item-text-title text-[#60809F] font-[inter-regular] text-[20px] not-italic font-normal leading-[normal] mb-[9px]">
                    Workflow name
                  </div>
                  <div className="workflow-listing-item-text-desc text-[#60809F] font-[inter-regular] text-[15px] not-italic font-normal leading-[normal] opacity-70">
                    Last updated 29 minutes ago | Created 23 April
                  </div>
                </div>
                <div className="workflow-listing-item-action flex items-center justify-end">
                  <div className="workflow-listing--switch node-comm-switch relative flex items-center justify-center mr-[20px]">
                    <input
                      type="checkbox"
                      id="send-query-parameters-switch"
                      className="h-[0] w-[0] invisible"
                    />
                    <label
                      className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                      htmlFor="send-query-parameters-switch"
                    >
                      Toggle
                    </label>
                  </div>
                  <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
                </div>
              </div>
              <div className="workflow-listing-item flex items-start justify-between [box-shadow:0px_8px_10.1px_rgba(191,_204,_217,_0.46)] mb-[16px] bg-[#fff] rounded-[6px] px-[30px] pt-[22px] pb-[18px]">
                <div className="workflow-listing-item-text">
                  <div className="workflow-listing-item-text-title text-[#60809F] font-[inter-regular] text-[20px] not-italic font-normal leading-[normal] mb-[9px]">
                    Workflow name
                  </div>
                  <div className="workflow-listing-item-text-desc text-[#60809F] font-[inter-regular] text-[15px] not-italic font-normal leading-[normal] opacity-70">
                    Last updated 29 minutes ago | Created 23 April
                  </div>
                </div>
                <div className="workflow-listing-item-action flex items-center justify-end">
                  <div className="workflow-listing--switch node-comm-switch relative flex items-center justify-center mr-[20px]">
                    <input
                      type="checkbox"
                      id="send-query-parameters-switch"
                      className="h-[0] w-[0] invisible"
                    />
                    <label
                      className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                      htmlFor="send-query-parameters-switch"
                    >
                      Toggle
                    </label>
                  </div>
                  <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
                </div>
              </div>
              <div className="workflow-listing-item flex items-start justify-between [box-shadow:0px_8px_10.1px_rgba(191,_204,_217,_0.46)] mb-[16px] bg-[#fff] rounded-[6px] px-[30px] pt-[22px] pb-[18px]">
                <div className="workflow-listing-item-text">
                  <div className="workflow-listing-item-text-title text-[#60809F] font-[inter-regular] text-[20px] not-italic font-normal leading-[normal] mb-[9px]">
                    Workflow name
                  </div>
                  <div className="workflow-listing-item-text-desc text-[#60809F] font-[inter-regular] text-[15px] not-italic font-normal leading-[normal] opacity-70">
                    Last updated 29 minutes ago | Created 23 April
                  </div>
                </div>
                <div className="workflow-listing-item-action flex items-center justify-end">
                  <div className="workflow-listing--switch node-comm-switch relative flex items-center justify-center mr-[20px]">
                    <input
                      type="checkbox"
                      id="send-query-parameters-switch"
                      className="h-[0] w-[0] invisible"
                    />
                    <label
                      className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                      htmlFor="send-query-parameters-switch"
                    >
                      Toggle
                    </label>
                  </div>
                  <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};
export default WorkFlow;
