

import { GetSchemes, ClassicPreset, NodeEditor } from "rete";
import { AreaExtensions } from "rete-area-plugin";
import WebHookTrigger from "../customization/webhookTrigger";
import HttpNode from "../customization/Httpnode";
import EditFields from "../customization/editFieldsNode";
import Codenodes from "../customization/codeNodes";
import MySqlNode from "../customization/mysqlNode";
import ScheduleTrigger from "../customization/ScheduleTrigger";

function serializePort(
  port:
    | ClassicPreset.Input<ClassicPreset.Socket>
    | ClassicPreset.Output<ClassicPreset.Socket>
) {
  return {
    id: port.id,
    label: port.label,
    socket: {
      name: port.socket.name
    }
  };
}

function serializeControl(control: ClassicPreset.Control) {
  if (control instanceof ClassicPreset.InputControl) {
    return {
      __type: "ClassicPreset.InputControl" as const,
      id: control.id,
      readonly: control.readonly,
      type: control.type,
      value: control.value
    };
  }
  return null;
}

type Schemes = GetSchemes<
  ClassicPreset.Node,
  ClassicPreset.Connection<ClassicPreset.Node, ClassicPreset.Node>
>;

export async function exportGraph(editor: NodeEditor<Schemes>) {
  const data: any = { nodes: [] };
  const nodes = editor.getNodes();

  for (const node of nodes) {
    console.log('node')
    console.log(node)
    const inputsEntries = Object.entries(node.inputs).map(([key, input]) => {
      return [key, input && serializePort(input)];
    });
    const outputsEntries = Object.entries(node.outputs).map(([key, output]) => {
      return [key, output && serializePort(output)];
    });
    const controlsEntries = Object.entries(node.controls).map(
      ([key, control]) => {
        return [key, control && serializeControl(control)];
      }
    );

    data.nodes.push({
      id: node.id,
      label: node.label,
      nodeData: node['nodeData'],
      httpData: node['httpData'],
      isNodeEnable: node['isNodeEnable'],
      response: node['response'],
      outputs: Object.fromEntries(outputsEntries),
      inputs: Object.fromEntries(inputsEntries),
      controls: Object.fromEntries(controlsEntries)
    });
  }

  return data;
}

export async function importGraph(data: any, editor: NodeEditor<Schemes>, dataflow, log) {
 

  for (const { id, label, inputs, outputs, controls, nodeData, httpData, isNodeEnable } of data.nodes) {
    let node;
    console.log(nodeData)
    // Create nodes based on label
    if (nodeData.name === "Webhook") {
      node = new WebHookTrigger(dataflow, () => { }, log, editor);
    } else if (nodeData.name === "HTTP Request") {
      node = new HttpNode(dataflow, (url) => { }, log, editor);
    } else if (nodeData.name === "Edit Fields") {
      node = new EditFields(dataflow, () => { }, log, editor);
    }  else if (nodeData.name === "Code") {
      node = new Codenodes(dataflow, () => { }, log, editor);
    }   else if (nodeData.name === "Mysql") {
      node = new MySqlNode(dataflow, () => { }, log, editor);
    }    else if (nodeData.name === "Schedule Trigger") {
      node = new ScheduleTrigger(dataflow, () => { }, log, editor);
    }
    console.log('HI Node')
    console.log(node)
    // node.id = id;
    node.nodeData = nodeData
    node.httpData = httpData
    node.isNodeEnable = isNodeEnable
    
    // Process inputs
    if (inputs) {
      Object.entries(inputs).forEach(([key, input]: [string, any]) => {
        if (!node.inputs[key]) {
          const socket = new ClassicPreset.Socket(input.socket.name);
          const inp = new ClassicPreset.Input(socket, input.label);
          inp.id = input.id;
          node.addInput(key, inp);
        }
      });
    }

    // Process outputs
    if (outputs) {
      Object.entries(outputs).forEach(([key, output]: [string, any]) => {
        if (!node.outputs[key]) {
          const socket = new ClassicPreset.Socket(output.socket.name);
          const out = new ClassicPreset.Output(socket, output.label);
          out.id = output.id;
          node.addOutput(key, out);
        }
      });
    }

    // Process controls
    if (controls) {
      Object.entries<ReturnType<typeof serializeControl>>(controls).forEach(([key, control]) => {
        //if (!control) return;
        console.log(control)  
        node.setControlValue(key,control.value)
      });
    }
    console.log('Here is updatenode')
    console.log(node)
    await editor.addNode(node);
  }
}



/* void (async function () {
  const editor = new NodeEditor<Schemes>();
  const socket = new ClassicPreset.Socket("My socket");
  const node = new ClassicPreset.Node("Label");

  node.addInput("port", new ClassicPreset.Input(socket, "label"));
  node.addOutput("port", new ClassicPreset.Output(socket, "Label"));
  node.addControl(
    "control",
    new ClassicPreset.InputControl("text", { initial: "data" })
  );

  await editor.addNode(node);

  const data = await exportGraph(editor);

  document.body.innerText = JSON.stringify(data, null, "\t");

  const newEditor = new NodeEditor<Schemes>();

  await importGraph(data, newEditor);

  console.log(newEditor.getNodes());
})(); */
