import { ClassicPreset } from "rete";
import { DataflowEngine, DataflowNode } from "rete-engine";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import io from "socket.io-client";
import { Schemes } from "../rete/types";
import {
  ActionSocket,
  TextSocket,
  copyNodeWithConnections,
  runNodes,
} from "../editor";
import { URLS } from "../utils";
import { Connection } from "rete-react-plugin/_types/presets/classic";
class WebHookTrigger
  extends ClassicPreset.Node<
    {},
    {
      output: ClassicPreset.Socket;
    },
    {
      url: ClassicPreset.InputControl<"text">;
      method: ClassicPreset.InputControl<"text">;
      path: ClassicPreset.InputControl<"text">;
    }
  >
  implements DataflowNode
{
  [x: string]: any;
  width = 800;
  height = 600;
  httpData: any = "";
  response: any;
  socket;
  isNodeEnable = true;
  isEntryNode = true;
  nodeData = {
    imgSrc: "../../images/webhook-node-icon.svg",
    name: "Webhook",
    description: "Trigger a webhook",
  };
  constructor(
    public dataflow: DataflowEngine<Schemes>,
    public onClick: (jsonData: any) => void,
    public log: (text: string) => void,
    public editor
  ) {
    super("Webhook Trigger");

    const uuid = uuidv4();
    this.addControl(
      "path",
      new ClassicPreset.InputControl("text", {
        initial: uuid,
      })
    );
    this.addControl(
      "url",
      new ClassicPreset.InputControl("text", {
        initial: URLS.BACK_END + "/v1/rete/webhook",
      })
    );

    const control2 = new ClassicPreset.InputControl("text", {
      initial: "GET",
    });

    this.addControl("method", control2);
    this.addOutput(
      "output",
      new ClassicPreset.Output(new TextSocket(), "Exec")
    );
    this.socket = io(URLS.BACK_END);
    this.setupSocketListeners(() => {});
  }
  dupicateNode() {
    copyNodeWithConnections(this.id);
  }
  setupSocketListeners(cb) {
   
  }

  worker(node: any, inputs: any, outputs: any) {
    console.log("Worker is executing for Webhook Trigger");

    // Check if webhook response is available
    if (this.httpData) {
      outputs["output"] = this.httpData; // Pass webhook response to output socket
    } else {
      outputs["output"] = null; // No data to pass if webhook hasn't been triggered
    }

    console.log("Outputs:", outputs); // Debugging to verify outputs
  }
  setControlValue(controlName: string, value: any) {
    const control = this.controls[controlName];
    
    if (control) {
      control.value = value; // Set the value of the control
      console.log(`Set ${controlName} to:`, value);
       
      // Optionally trigger an update on the editor to reflect the new value
     // this.editor.trigger('process');
    } else {
      console.error(`Control ${controlName} not found`);
    }
  }
  data(): { output: any } {
    console.log('DATA-method-out')
    console.log(this.httpData)
    return {
      output: this.httpData,
    };
  }
  getBaseUrl() {
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}${port ? ":" + port : ""}`;
  }
  validateRequestConfig(config) {
    const errors = [];

    // Validate non-empty strings
    ["url", "methord"].forEach((key) => {
      if (typeof config[key] !== "string" || config[key].trim() === "") {
        errors.push(`Invalid or missing '${key}'.`);
      }
    });
    return errors.length > 0 ? errors : null;
  }
  triggerFrower() {}
  reExecuteData() {
    console.log("Re-executing data method...");

    // Call the data method to update node state
    const newData = this.data();

    // If you want to trigger re-rendering, use update() or trigger editor re-render
  //  this.update();
    this.editor.trigger('process'); // Trigger process to re-execute nodes
    console.log("Data method re-executed with data: ", newData);
  }
  execute(input: "exec", forward: (output: "output", data?: any) => void) {
    if (this.isNodeEnable) {
      //forward("output")
      const socket = io(URLS.BACK_END); // Connect to socket
      // socket.on("webhookTrigger", (e) =>{
      // console.log(e.uniqueId+'-----'+this.controls.path.value)
      // if(e.uniqueId===this.controls.path.value){
      //this.httpData=JSON.stringify(e.responce)
      console.log("Hello from the main calss");
      console.log(this.httpData);
      
      forward(
        "output",
        this.httpData
      );
      console.log("Connected to server");
      // }
      //});
    }
    // Add code here to make API call with the URL and method
  }
}

export default WebHookTrigger;
