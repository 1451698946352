import React, { useState } from 'react';
import Select from 'react-select';
import CustomSelectIconOption from '../CustomSelectIconOption';
import CustomSelectIconValue from '../CustomSelectIconValue';


const AddUser = ({ isOpen, onClose, items, onContinue }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selected) => {
        setSelectedOption(selected);
    };

    const handleContinue = () => {
        if (onContinue) {
            onContinue(selectedOption);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="add-user-popup z-[100] fixed top-[0] left-[0] w-full h-full bg-[rgba(0,_0,_0,_0.5)] flex justify-center items-center">
            <div className="w-[624px] bg-[#fff] rounded-[6px] relative">
                <div className="popup-header rounded-tl-[14px] rounded-tr-[14px] bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px]">
                    <div className="text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[28px] -tracking-[0.34px]">Create a new user</div>
                    <button
                        className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
                        onClick={onClose}
                    >
                        <img src="../../images/close-popup.svg" alt="popup-close" />
                    </button>

                </div>
                <div className="popup-body p-[30px]">
                    <div className="add-user-popup-upload flex items-center justify-start mb-[30px]">
                        <div className="add-user-popup-upload-img mr-[15px]">
                            <img src="../../images/blank-avatar.png" alt="blank-avatar" width={64} />
                        </div>
                        <div className="add-user-popup-upload-btn">
                            <span className="rounded-[4px] w-[92px] h-[24px] border [border-style:solid] [border-color:#A0A0A6] block text-[12px] text-center flex items-center justify-center">Upload Photo</span>
                        </div>
                    </div>
                    <div className="add-user-popup-upload-fields">
                        <div className="add-user-popup-upload-fields-wrap flex items-start justify-between">
                            <div className="midform-field mb-[20px] relative w-[48.5%]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                    First name <span className="text-[#df1941]">*</span>
                                </label>
                                <input
                                    className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                    type="text"
                                    name="email"
                                    placeholder="Type name"
                                />
                            </div>
                            <div className="midform-field mb-[20px] relative w-[48.5%]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                    Last name <span className="text-[#df1941]">*</span>
                                </label>
                                <input
                                    className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                    type="text"
                                    name="email"
                                    placeholder="Type name"
                                />
                            </div>
                        </div>
                        <div className="midform-field mb-[20px] relative">
                            <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                Email <span className="text-[#df1941]">*</span>
                            </label>
                            <input
                                className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                type="email"
                                name="email"
                                placeholder="Type email"
                            />
                        </div>
                        <div className="midform-field midform-field-select relative mb-[30px]">
                            <label className="midform-field-label block text-[#74757D] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">Type of role <span className="text-[#df1941]">*</span></label>

                            <Select
                                className="midform-select-main add_user_role_dropdown"
                                classNamePrefix="midform-select"
                                value={selectedOption}
                                onChange={handleChange}
                                options={items}
                                placeholder="Select role"
                                isSearchable={false}
                                components={{
                                    Option: CustomSelectIconOption,
                                    SingleValue: CustomSelectIconValue,
                                }}
                            />

                        </div>
                    </div>
                    <div className="popup-actions flex items-center justify-center">
                        <button className="flex items-center justify-center [transition:0.3s_all_ease] bg-[#E8F0FC] hover:bg-[#146DE0] text-[#146DE0] hover:text-[#fff] text-[14px] font-[inter-medium] w-[150px] h-[36px] rounded-[6px]">
                            <span>Cancel</span>
                        </button>
                        <button className="flex items-center justify-center [transition:0.3s_all_ease] bg-[#146DE0] hover:bg-[#E8F0FC] text-[#fff] hover:text-[#146DE0] text-[14px] font-[inter-medium] w-[150px] h-[36px] rounded-[6px] ml-[8px]">
                            <span>Invite user</span>
                        </button>
                    </div>
                </div>                
            </div>
        </div>
    );
};

export default AddUser;
