import { ClassicPreset } from "rete";
import { DataflowEngine, DataflowNode } from "rete-engine";
import { Schemes } from "../rete/types";
import { ActionSocket, TextSocket } from "../editor"; 
class Codenodes
  extends ClassicPreset.Node<
    {
        input: ClassicPreset.Socket;
    },
    {
      output: ClassicPreset.Socket;
    },
    {
      dataCode: ClassicPreset.InputControl<"text">;
    }
  >
  implements DataflowNode
{
    width = 600;
    height = 400;
    httpData: any;
    response: any;
    nodeData={
      imgSrc:"../../images/code-node-icon.svg",
      name: "Code", 
      description:"Code"
    }
  isNodeEnable=true
  constructor(
    public dataflow: DataflowEngine<Schemes>,
    public onClick: (jsonData: any) => void,
    public log: (text: string) => void,
    public editor
  ) {
    super("Code");

    this.addControl(
      "dataCode",
      new ClassicPreset.InputControl("text", { 
      })
    );
    this.addInput(
      "input",
      new ClassicPreset.Input(new TextSocket(), "input")
    );
    this.addOutput(
      "output",
      new ClassicPreset.Output(new TextSocket(), "Output")
    );
  }
  setControlValue(controlName: string, value: any) {
    const control = this.controls[controlName];
    
    if (control) {
      control.value = value; // Set the value of the control
      console.log(`Set ${controlName} to:`, value);
       
      // Optionally trigger an update on the editor to reflect the new value
     // this.editor.trigger('process');
    } else {
      console.error(`Control ${controlName} not found`);
    }
  }
  worker(node, inputs, outputs) {
     console.log('worker')
}
  data( 
    inputs: Record<string, any>
  ): Record<string, any> | Promise<Record<string, any>> {
   // throw new Error("Method not implemented.");
   const dataCode = inputs.dataCode;
   return {
    output:this.httpData,
  };
  }
  async execute(input: "exec", forward: (output: "exec") => void) {
   
    const inputs = await this.dataflow.fetchInputs(this.id);
    console.log(inputs.input[0])
    this.controls.dataCode.value=JSON.parse(inputs.input[0])
    
    //let timeData=JSON.parse(this.controls.dataCode.value as string) 
 
  }
}

export default Codenodes;
